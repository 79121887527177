import { BaseResponse } from "~/types/Common/APIs";
import { Bundle } from "~/types/InformativePages/Header/CategoryBundleBrand";

export default function useBundles() {
  const loading = ref(false);
  const bundles = ref<Bundle[]>([]);
  const nuxtApp = useNuxtApp();

  const sortBundles = async (sort: string) => {
    if (nuxtApp.$features?.bundles) {
      loading.value = true;
      const { res } = await useApi<BaseResponse<Bundle[]>>(
        `/api/v1/bundles?sort=${sort}`,
      );
      if (res?.data) bundles.value = res.data;
      loading.value = false;
    }
  };

  const getBundles = async () => {
    if (nuxtApp.$features?.bundles) {
      loading.value = true;
      const { res } = await useApi<BaseResponse<Bundle[]>>(`/api/v1/bundles`);
      if (res?.data) bundles.value = res.data;
      loading.value = false;
    }
  };

  return {
    bundles,
    loading,
    getBundles,
    sortBundles,
  };
}
